import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import Amplify, { API } from 'aws-amplify';
import { NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { Events, NavController, Platform } from '@ionic/angular';


@Component({
  selector: 'app-vod-date-time-picker',
  templateUrl: './vod-date-time-picker.page.html',
  styleUrls: ['./vod-date-time-picker.page.scss'],
})
export class VodDateTimePickerPage implements OnInit {
  data: any;
  streamname: any;
  url: [];
  err: any;
  errmg: any;
  show:boolean = true;
  date = new Date();
  newDate = []
  newTime = []
  myDate: String = new Date(this.date.getTime()-this.date.getTimezoneOffset()*60000).toISOString();

  constructor(private route: ActivatedRoute, public loadingController: LoadingController, private router: Router, public events: Events) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.data = this.router.getCurrentNavigation().extras.state.component_items;
        this.streamname = this.data['stream-name'];
      }
    });
    this.events.subscribe('page:RefreshvodDTP', (component) => {
      this.data = component;
      this.streamname = this.data['stream-name'];
      this.ngOnInit();
    });
  }

  // Navigate to videofeed route with parameters
  openVOD() {
    this.newDate = this.myDate.split('.');

    let navigationExtras: NavigationExtras = {
      state: {
        startDate: this.newDate[0],
        streamName: this.streamname
      }
    };

    this.events.publish('page:RefreshvodFeed',
    {state: {
      startDate: this.newDate[0],
      streamName: this.streamname
      }
    }
    );
    this.router.navigate(['/tabs/vod-feed'], navigationExtras);

  }

  goback() {
    this.router.navigate(['/tabs/tab2']);

  }

  ngOnInit() {
  }

}
